import { Button, Paper } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Add } from "@mui/icons-material";
import { showCollecotrFormAct } from "../slice/collectors.slice";
export default function CollectorsControls() {
  const dispatch = useAppDispatch()
  const {} = useAppSelector((state) => state.users) 

  const showCollectorForm = () => dispatch(showCollecotrFormAct(true))
  return (
    <Paper sx={{padding: 1, marginBottom: 1}}>
      <Button onClick={showCollectorForm} variant="outlined" endIcon={<Add/>}>Add Collector</Button>
    </Paper>
  )
}