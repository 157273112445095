import { Chip, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, ThemeProvider, Tooltip } from "@mui/material"
import { useAppSelector } from "../../../../app/hooks"
import { themeCondense } from "../../../../app/themes/theme-condense"
import { numberToCurrency } from "../../../../utils/numbers.utils"

export default function DownloadedPaysLogs() {
  const { paymentsLogs, userSearch } = useAppSelector((state) => state.downloadPaysHistory) 
  return (
    <ThemeProvider theme={themeCondense}>
      <Paper sx={{padding: 2, marginBottom: 1}}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="right"> Value </TableCell>
                <TableCell align="right"> Collector </TableCell>
                <TableCell align="right"> Usd </TableCell>
                <TableCell align="right"> COP </TableCell>
                <TableCell align="right"> Worker </TableCell>
                <TableCell align="right"> Lead Worker </TableCell>
                <TableCell align="right"> Sub Lead </TableCell>
                <TableCell align="right"> Lead Office </TableCell>
                <TableCell align="right"> Partners </TableCell>
                <TableCell align="center"> Main 1 </TableCell>
                <TableCell align="center"> Main 2 </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentsLogs.map((p) => 
                <TableRow key={p._id}>
                  <TableCell align="right">{numberToCurrency(p.payment.value)}  </TableCell>
                  <TableCell align="right">{numberToCurrency(p.collector.value)}  </TableCell>
                  <TableCell align="right">{numberToCurrency(p.usdPrice)}  </TableCell>
                  <TableCell align="right">{p.copValue }  </TableCell>
                  <TableCell align="right">
                    <Tooltip  placement="top" title={`${p.worker.user?.email}`}>
                      <Chip size="small" color={userSearch === p.worker.user?._id ? "success" : "default"} label={numberToCurrency(p.worker.value)}/>
                      </Tooltip>
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip placement="top" title={`${p.leadWorker.user?.email}`}>
                      <Chip size="small" color={userSearch === p.leadWorker.user?._id ? "success" : "default"} label={ numberToCurrency(p.leadWorker.value)} />
                    </Tooltip>
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip placement="top" title={`${p.subleads.users?.map((u, i) => `${u.email}`).join(',')}`}>
                      <Chip size="small" color={p.subleads.users?.filter((u) => u._id === userSearch).length > 0  ? 'success' : "default"} label={numberToCurrency(p.subleads.value)}/>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip placement="top" title={`${p.officeLead.users.map((u) => `${u.email}|${numberToCurrency(p.officeLead.usersPercentage.filter((u2) =>u2.user === u._id)[0]?.value)}`).join('\n')}`}>
                      <Chip color={ p.officeLead.users?.filter((u) => u._id === userSearch).length > 0 ? "success" : "default"} label={numberToCurrency(p.officeLead.value)} /></Tooltip>
                  </TableCell>
                  <TableCell align="right">{numberToCurrency(p.partners.value)}  </TableCell>
                  <TableCell align="center"> 
                      <Chip size="small" color="success" label={numberToCurrency(p.main1)} />
                  </TableCell>
                  <TableCell align="center"> 
                      <Chip size="small" color="success" label={numberToCurrency(p.main2)} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell align="right"> {numberToCurrency(paymentsLogs.reduce((acc, p) => acc + p.payment.value , 0))} </TableCell>
                <TableCell align="right"> {numberToCurrency(paymentsLogs.reduce((acc, p) => acc + p.collector.value , 0))} </TableCell>
                <TableCell align="right"> {numberToCurrency(paymentsLogs.reduce((acc, p) => acc + p.usdPrice , 0)/paymentsLogs.length)} </TableCell>
                <TableCell align="right"> {numberToCurrency(paymentsLogs.reduce((acc, p) => acc + p.copValue , 0))} </TableCell>
                <TableCell align="right"> {numberToCurrency(paymentsLogs.reduce((acc, p) => acc + p.worker.value , 0))} </TableCell>
                <TableCell align="right"> {numberToCurrency(paymentsLogs.reduce((acc, p) => acc + p.leadWorker.value , 0))} </TableCell>
                <TableCell align="right"> {numberToCurrency(paymentsLogs.reduce((acc, p) => acc + p.subleads.value , 0))} </TableCell>
                <TableCell align="right"> {numberToCurrency(paymentsLogs.reduce((acc, p) => acc + p.officeLead.value , 0))} </TableCell>
                <TableCell align="right"> {numberToCurrency(paymentsLogs.reduce((acc, p) => acc + p.partners.value , 0))} </TableCell>
                <TableCell align="center"><Chip size="small" color="success" label={numberToCurrency(paymentsLogs.reduce((acc, p) => acc + p.main1 , 0))}/></TableCell>
                <TableCell align="center"><Chip size="small" color="success" label={numberToCurrency(paymentsLogs.reduce((acc, p) => acc + p.main2 , 0))} /></TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </ThemeProvider>
  )
}