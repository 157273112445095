export class OmegaSoftConstants {
  static localstorageAuthKey = "omega_web_auth"
  static localstorageTokenKey = "omega_web_token"
  static googleApiKey = "AIzaSyB42KE_SyTx0mvEWe1Z4EU9ramLWn4JBF4"

  static alcatronId = '669ec1d134a4a5e23f8c4536'
  static arsanId = '66292558df71af265863916e'

  static collectorIdDefault = '662acedb5f9f6dc51b6b095b'

  static maxPlatformValue = 4500000

  static superUsersId = ['kdev999','arsan','alcatron']

  static collectorLocationEnum = [{_id: 'us', name: 'US'}, {_id: 'co', name: 'CO'}]

}
