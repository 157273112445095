import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Edit, Settings } from "@mui/icons-material";
import { useEffect } from "react";
import { collectorByIdThunk, fetchCollectorsThunk, setCollectorToEditAct } from "../slice/collectors.slice";
export default function CollectorsList() {
  const dispatch = useAppDispatch()
  const { collectors, } = useAppSelector((state) => state.collectors) 

  useEffect(() => {
    dispatch(fetchCollectorsThunk())
   }, [])

  const editCollector = (collectorId: string) => {
    dispatch(setCollectorToEditAct(collectorId))
    dispatch(collectorByIdThunk(collectorId))
  }
  return (
    <Paper sx={{padding: 2, marginBottom: 1}}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>Usuario</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Semanal</TableCell>
              <TableCell>Mensual</TableCell>
              <TableCell>Anual</TableCell>
              <TableCell> <Settings/> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {collectors.map((el, i) =>
              (
                <TableRow key={i}>
                  <TableCell>{el.title}</TableCell>
                  <TableCell>{el.user.email}</TableCell>
                  <TableCell>{el.location}</TableCell>
                  <TableCell>{el.limitWeek}</TableCell>
                  <TableCell>{el.limitMonth}</TableCell>
                  <TableCell>{el.limitYear}</TableCell>
                  <TableCell> <IconButton onClick={() => editCollector(el._id)} ><Edit  /></IconButton> </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}