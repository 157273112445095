import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, ThemeProvider } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { showResumeDialogAct } from "../../business-logic/download-payment-history.slice";
import { numberToCurrency } from "../../../../utils/numbers.utils";
import { themeCondense } from "../../../../app/themes/theme-condense";
export default function UserResumeDownloadedPaysDialog() {
  const dispatch = useAppDispatch()
  const { showResumeDialog, usersResume } = useAppSelector((state) => state.downloadPaysHistory) 

  const closeDialog = () => dispatch(showResumeDialogAct(false))
  return (
    <>
      <Dialog open={showResumeDialog}>
        <IconButton onClick={closeDialog} className="closeDialog"> <Close /></IconButton>
        <DialogTitle> Resumen de usuarios </DialogTitle>
        <DialogContent sx={{minWidth: 500}}>
          <ThemeProvider theme={themeCondense}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys( usersResume).map((userId) =>
                  <TableRow key={userId}>
                    <TableCell>{usersResume[userId].email}</TableCell>
                    <TableCell align="right">{numberToCurrency(usersResume[userId].total)}</TableCell>
                  </TableRow>
                )}

              </TableBody>
            </Table>
          </ThemeProvider>
        </DialogContent>
      </Dialog>
    </>
  )
}