import { Box, Button, Chip, Grid, Paper, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import AppTextField from "../../../../app/components/app-textfield";
import { changePercentageSinglePartnersAct, saveCampaignUtilityThunk, showModalAddPatrnerPercentageAct } from "../../business-logic/download-payment-history.slice";
import { numberToCurrency } from "../../../../utils/numbers.utils";
import { MultiplePercentageType, SinglePercentageData } from "../../business-logic/download-payment.state";
import { pushAlertAction } from "../../../dashboard/dashboard.slice";
export default function PartnersMain1List() {
  const dispatch = useAppDispatch()
  const { partnersPercentage, mainExpensesPercentage: {partnersExpense, main1WithoutPartners, main1WithoutPlatform, platformUtility}, campaignPicked, campaignDownloaded } = useAppSelector((state) => state.downloadPaysHistory) 
  const showModal = () => dispatch(showModalAddPatrnerPercentageAct(true))

  const saveCampaign = () => {
    const userPercentageData: SinglePercentageData[] = partnersPercentage.map((p) => ({percentage: p.percentage, value: p.value, user: p.user} as SinglePercentageData)) 
    const partners: MultiplePercentageType = {
      before: main1WithoutPlatform,
      after: main1WithoutPartners,
      value: partnersExpense,
      percentage: partnersExpense,
      userPercentageData,
      users: partnersPercentage.map((p) => p.user)  
    }
    
    dispatch(pushAlertAction({
      title: 'Confirmar',
      closeOnAction: true,
      message: 'Despues de guardar no podras modificar los porcentajes de los partners',
      type: 'warning',
      actions: [
        {title: 'Aceptar', action: () => {
          dispatch(saveCampaignUtilityThunk({campaignId: campaignPicked, partners, mainUtility: main1WithoutPartners, platform: platformUtility}))}
        }
      ]
    }))
  }
  return (
    <Paper sx={{padding: 1, marginBottom: 1}}>
      <Typography variant="h5" width={'100%'} textAlign={'center'} marginY={1}>Partners</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {partnersPercentage.map((p,index) =>
            <Box marginBottom={1} key={`${index}mainPartnerP`}>
              <AppTextField
                type="number"
                inputProps={{step: 1, min: 0}}
                startCompontent={p.userNick}
                endComponent={`$ ${numberToCurrency(p.value)}`}
                value={p.percentage}
                onChange={(e) => dispatch(changePercentageSinglePartnersAct({index, percentage: e.val}))}
              />
            </Box>
          )}
          <Grid container>
            <Grid item xs={6}>
              <Button fullWidth color="primary" variant="outlined"  onClick={showModal}> ADD PARTNER </Button>
            </Grid>
            <Grid item xs={6} textAlign={'center'}>
              <Chip color="success"  label={`$ ${numberToCurrency(partnersExpense)}`} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} display={'flex'} justifyContent={'center'} alignItems={'center'}>   
            <Typography variant="h6">
              Main 1 Utility: <strong>${numberToCurrency(main1WithoutPartners)}</strong> 
            </Typography>
        </Grid>
        <Grid item xs={6}>
          <Button fullWidth variant="contained" color="secondary" disabled={campaignDownloaded} onClick={saveCampaign}> SAVE </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}