import { Chip, Grid, Paper, Typography } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { numberToCurrency } from "../../../../utils/numbers.utils"
import AppTextField from "../../../../app/components/app-textfield"
import { useEffect } from "react"
import { calculateExpensesPercentageAct, changeMainExpensesPercentageAct, changePlatformPercentageAct } from "../../business-logic/download-payment-history.slice"

export default function PercentageUtility() {
  const dispatch = useAppDispatch()
  const { totalExpenses, totalUtility, mainExpensesPercentage, calculateExpenses } = useAppSelector((state) => state.downloadPaysHistory) 
  const { expensesMain1, expensesMain2, main1, main2, main1leastExpenses, main2leastExpenses, platformPercentage, platformUtility, utilityLeastExpenses, main1PlatformPercentage, main2PlatformPercentage, main2WithoutPlatform, main1WithoutPlatform } = mainExpensesPercentage

  useEffect(() => {
    dispatch(calculateExpensesPercentageAct())
  }, [calculateExpenses])


  return (
    <>
      <Paper sx={{padding: 1, marginBottom: 1}}>
        <Grid container>
          <Grid item xs={6} textAlign={'center'}>
            <Typography variant="body1">
              Total Gastos: {numberToCurrency(totalExpenses)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" textAlign={'center'}>
              Total Utilidad: {numberToCurrency(totalUtility)}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{padding: 1, marginBottom: 1}}>
        <Grid container spacing={1}>
          <Grid item xs={6} textAlign={'center'}>
            <AppTextField 
              type="number"
              value={main1}
              onChange={(e) => dispatch(changeMainExpensesPercentageAct({main1: Number(e.val), main2}))}
              endComponent={<Chip size="small" color="warning" label={`$ ${numberToCurrency(expensesMain1)}`} />}
              />
          </Grid>
          <Grid item xs={6}>
            <AppTextField 
              type="number"
              value={main2}
              onChange={(e) => dispatch(changeMainExpensesPercentageAct({main1, main2: Number(e.val)}))}
              endComponent={<Chip size="small" color="warning" label={`$ ${numberToCurrency(expensesMain2)}`} />}
              //label="M2 % Gastos"
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{padding: 1, marginBottom: 1}}>
        <Grid container>
          <Grid item xs={4} textAlign={'center'}>
            <Typography variant="body1">
              Main 1 Utiidad: {numberToCurrency(main1leastExpenses)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1" textAlign={'center'}>
              Main 2 Utilidad: {numberToCurrency(main2leastExpenses)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1" textAlign={'center'}>
              Total: {numberToCurrency(utilityLeastExpenses)}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{padding: 1, marginBottom: 1}}>
        <Grid container>
          <Grid item xs={4} textAlign={'center'} alignItems={'end'}>
            <AppTextField 
              type="number"
              startCompontent="Platform %"
              value={platformPercentage}
              onChange={(e) => dispatch(changePlatformPercentageAct(e.val))}
              endComponent={<Chip size="small" color="warning" label={`$ ${numberToCurrency(platformUtility)}`} />}
              />
          </Grid>
          <Grid item xs={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Typography variant="body1" textAlign={'center'}>
              Main 1 Platform: {numberToCurrency(main1PlatformPercentage)}
            </Typography>
          </Grid>
          <Grid item xs={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Typography variant="body1" textAlign={'center'}>
              Main 2 Platform: {numberToCurrency(main2PlatformPercentage)}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
        <Paper sx={{padding: 1, marginBottom: 1}}>
        <Grid container>
          <Grid item xs={6} textAlign={'center'}>
            <Typography variant="body1">
              Main 1 Utiidad: <Chip component={'span'} color="success" label={numberToCurrency(main1WithoutPlatform)}/>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" textAlign={'center'}>
              Main 2 Utilidad: <Chip component={'span'} color="success" label={numberToCurrency(main2WithoutPlatform)}/>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}